import React from "react"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import Button, {ButtonLoading} from "../../components/Button"
import {ConfirmModal} from "../../components/Modal"
import api from "../../services"
import requestError from "../../services/requestError"
import {ASalesPersonDashboard} from "../../services/types.generated"
import {BusinessInfoModal} from "./BusinessInfoModal.tsx"
import {useSalesPersonDashboardQuery} from "./utils.tsx"

export const SellingOfferActions: React.FC<{
  offer: ASalesPersonDashboard["assignment_offers"][number]
  refetch: () => void
}> = ({offer, refetch}) => {
  const {t} = useTranslation()

  const [isDeclineModalOpen, setIsDeclineModalOpen] = React.useState(false)
  const [isBusinessInfoModalOpen, setIsBusinessInfoModalOpen] = React.useState(false)

  const {data} = useSalesPersonDashboardQuery()

  const handleAccept = React.useCallback(async () => {
    try {
      await api.assignmentOffers.acceptPartialUpdate(offer.id)
      refetch()
      toast.success(t("SalespersonDashboard_You've accepted the offer."))
    } catch (e) {
      requestError(e)
    }
  }, [offer.id, refetch, t])

  const handleDecline = React.useCallback(async () => {
    try {
      await api.assignmentOffers.rejectPartialUpdate(offer.id)
      refetch()
      setIsDeclineModalOpen(false)
    } catch (e) {
      requestError(e)
    }
  }, [offer.id, refetch])

  const handleClickAccept = React.useCallback(async () => {
    if (!data?.data.dashboard.business_data_filled) {
      setIsBusinessInfoModalOpen(true)
      return
    }

    return handleAccept()
  }, [data?.data.dashboard.business_data_filled, handleAccept])

  if (!data) {
    return null
  }

  return (
    <div className={"flex flex-row gap-4 pr-3"}>
      <Button variant={"outlined"} color={"gray"} onClick={() => setIsDeclineModalOpen(true)}>
        {t("T_Decline")}
      </Button>
      <ButtonLoading onClick={handleClickAccept}>{t("T_Accept")}</ButtonLoading>

      <BusinessInfoModal
        isOpen={isBusinessInfoModalOpen}
        onClose={() => setIsBusinessInfoModalOpen(false)}
        onSuccess={handleAccept}
      />

      <ConfirmModal
        variant={"warning"}
        onConfirm={handleDecline}
        title={t("SalespersonDashboard_Are you sure you want to decline?")}
        isOpen={isDeclineModalOpen}
        onClose={() => setIsDeclineModalOpen(false)}
        cancelButtonText={
          <span>
            <b>{t("T_No")}</b>
            {t("T_, I clicked by mistake")}
          </span>
        }
        confirmButtonText={t("T_Yes, I am sure")}
      >
        {t(
          "SalespersonDashboard_We might not be able to match you with different company in your location. This company would love to grow in your city. Let's help them scale together!"
        )}
      </ConfirmModal>
    </div>
  )
}
