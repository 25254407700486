import React, {ComponentProps, useMemo} from "react"
import {twMerge} from "tailwind-merge"

import {TColor, TColorVariantClasses} from "../../types"
import {UnstyledLink} from "../Link"
import {MenuContext} from "./index.tsx"

type Props = React.PropsWithChildren<{
  variant?: TColor
  to?: string
  onClick?: () => void
  ghost?: boolean
  linkProps?: Omit<ComponentProps<typeof UnstyledLink>, "to">
}>

const classNameByVariant = {
  ghost: {
    primary: "text-cr-grey-80 hover:text-white hover:bg-cr-blue",
    secondary: "text-cr-grey-80 hover:text-white hover:bg-cr-cyan",
    neutral: "text-cr-grey-80 hover:text-white hover:bg-cr-grey-50",
    success: "text-cr-grey-80 hover:text-white hover:bg-cr-green",
    warning: "text-cr-grey-80 hover:text-white hover:bg-cr-yellow",
    error: "text-cr-grey-80 hover:text-white hover:bg-cr-red",
  },
  regular: {
    primary: "text-cr-blue hover:text-white hover:bg-cr-blue",
    secondary: "text-cr-cyan hover:text-white hover:bg-cr-cyan",
    neutral: "text-cr-grey-50 hover:text-white hover:bg-cr-grey-50",
    success: "text-cr-green hover:text-white hover:bg-cr-green",
    warning: "text-cr-yellow hover:text-white hover:bg-cr-yellow",
    error: "text-cr-red hover:text-white hover:bg-cr-red",
  },
} as const satisfies {
  ghost: TColorVariantClasses
  regular: TColorVariantClasses
}

export const MenuItem: React.FC<Props> = ({variant = "primary", ghost, linkProps, onClick, to, children}) => {
  const Wrapper = useMemo(
    () =>
      to
        ? (props: React.ComponentProps<typeof UnstyledLink>) => <UnstyledLink {...props} {...linkProps} />
        : (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => <button {...props} />,
    [to, linkProps]
  )

  const {onClose} = MenuContext.useContext()

  return (
    <div>
      {to || onClick ? (
        <Wrapper
          to={to}
          onClick={() => {
            onClick?.()
            onClose()
          }}
          className={twMerge([
            "flex w-full items-center rounded-md px-2 py-2 text-sm",
            classNameByVariant[ghost ? "ghost" : "regular"][variant],
          ])}
        >
          {children}
        </Wrapper>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}
