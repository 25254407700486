import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {TrashIcon} from "@heroicons/react/24/outline"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../components/Button.tsx"
import {CheckboxConnected} from "../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../components/fields/components.tsx"
import {InputConnected} from "../../../components/fields/Input.tsx"
import {TextareaConnected} from "../../../components/fields/Textarea.tsx"
import {Flyout, FlyoutDataRow, FlyoutGrid, FlyoutSectionRow} from "../../../components/Flyout.tsx"
import {DropdownConnected} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types.ts"
import {UnstyledLink} from "../../../components/Link.tsx"
import {TabItem, TabsContainer} from "../../../components/Tabs.tsx"
import {getCurrentLanguage} from "../../../i18n.ts"
import {useUpdateProspectMutation} from "../../../queries/prospects.ts"
import {ASalesCycleSegment} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useCountryOptions, usePrevious} from "../../../utils/hooks.tsx"
import {enumToOptions} from "../../../utils/i18n.tsx"
import {
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateEmail,
  validateNonemptyArray,
  validateNonemptyString,
} from "../../../utils/validation.ts"
import {DeletingContext, EditingContext, TEditingProspect, WarningsContext} from "./context.ts"

const validationSchema = z.object({
  organization_name: validateNonemptyString(),
  organization_number: z.string().nullable(),
  segment: validateNonemptyArray(validateNonemptyString()),
  city: z.string().nullable(),
  country: z.object({
    id: z.number({required_error: requiredFieldMessage}),
  }),
  website: z.string().nullable(),
  organization_phone_number: z.string().nullable(),

  contact_person_first_name: z.string().nullable(),
  contact_person_last_name: z.string().nullable(),
  position: z.string().nullable(),
  phone_number: z.string().nullable(),
  email: validateEmail().nullable(),

  additional_phone_number: z.string().nullable(),
  notes: z.string().nullable(),
})

export const EditingFlyout: React.FC = () => {
  const {t} = useTranslation()

  const countryOptions = useCountryOptions()

  const {value: currentProspect, setValue: setEditingProspect} = EditingContext.useContext()
  const deleteContext = DeletingContext.useContext()

  const warningsContextValue = WarningsContext.useProviderValue({})
  const setWarnings = warningsContextValue.setValue

  const lastProspect = usePrevious(currentProspect)
  const prospect = currentProspect ?? lastProspect

  const methods = useForm<TEditingProspect>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema),
  })
  const {getValues, setError, reset} = methods

  React.useEffect(() => {
    if (!currentProspect) {
      return
    }

    reset(currentProspect)
  }, [currentProspect, reset])

  React.useEffect(() => {
    if (lastProspect?.id === currentProspect?.id) {
      return
    }
    setWarnings({})
  }, [currentProspect?.id, lastProspect?.id, setWarnings])

  const updateMutation = useUpdateProspectMutation()

  const handleSubmit = React.useCallback(async () => {
    try {
      const response = await updateMutation.mutateAsync(getValues())
      const newProspect = response.data.prospect

      setWarnings(response.data.meta?.warnings ?? {})
      setEditingProspect(newProspect)

      toast.success(t("Prospects_EditingFlyout_SuccessToast"))
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "prospect")
    }
  }, [updateMutation, getValues, setWarnings, setEditingProspect, t, setError])

  const segmentOptions = React.useMemo(() => {
    const unknownSegments =
      prospect?.segment?.filter(segment => !(Object.values(ASalesCycleSegment) as string[]).includes(segment)) ?? []

    const unknownSegmentOptions: Array<TOption<string>> = unknownSegments.map(segment => ({
      value: segment,
      title: segment,
    }))

    return [...enumToOptions("Segment"), ...unknownSegmentOptions]
  }, [prospect?.segment])

  return (
    <WarningsContext value={warningsContextValue}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Flyout
            title={prospect?.organization_name}
            subtitle={`#${prospect?.id}`}
            buttons={
              <>
                <UnstyledLink
                  className={"cursor-pointer transition-all hover:text-cr-red"}
                  onClick={() => {
                    deleteContext.setValue(currentProspect)
                  }}
                >
                  <TrashIcon className={"h-6 w-6"} />
                </UnstyledLink>
                <div className={"flex items-center gap-4"}>
                  <Button variant={"outlined"} color={"gray"} onClick={() => setEditingProspect(null)}>
                    {t("Prospects_EditingFlyout_Cancel")}
                  </Button>
                  <ButtonForm>{t("Prospects_EditingFlyout_Save")}</ButtonForm>
                </div>
              </>
            }
            isOpen={!!currentProspect}
            onClose={() => setEditingProspect(null)}
          >
            <TabsContainer className={"px-6"} size={"sm"}>
              <TabItem isActive={true}>{t("Prospects_EditingFlyout_Detail")}</TabItem>
            </TabsContainer>
            <FlyoutGrid>
              <FlyoutSectionRow>{t("Prospects_EditingFlyout_Company")}</FlyoutSectionRow>

              <DataRow legend={t("Prospects_EditingFlyout_Name")} name={"organization_name"}>
                <InputConnected name={"organization_name"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_CompanyID")} name={"organization_number"}>
                <InputConnected name={"organization_number"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_Table_Segment")} name={"segment"}>
                <DropdownConnected
                  options={segmentOptions}
                  name={"segment"}
                  multiple
                  mainButtonClassName={"input-border-ghost-hover"}
                />
              </DataRow>
              <DataRow legend={t("Prospects_Table_City")} name={"city"}>
                <InputConnected name={"city"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_Country")} name={"country"}>
                <DropdownConnected
                  name={"country.id"}
                  options={countryOptions}
                  mainButtonClassName={"input-border-ghost-hover"}
                />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_Website")} name={"website"}>
                <InputConnected name={"website"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_Table_Phone")} name={"organization_phone_number"}>
                <InputConnected name={"organization_phone_number"} ghost={"hover"} />
              </DataRow>

              <FlyoutSectionRow>{t("Prospects_EditingFlyout_MainContact")}</FlyoutSectionRow>

              <DataRow legend={t("Prospects_EditingFlyout_FirstName")} name={"contact_person_first_name"}>
                <InputConnected name={"contact_person_first_name"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_LastName")} name={"contact_person_last_name"}>
                <InputConnected name={"contact_person_last_name"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_Table_Position")} name={"position"}>
                <InputConnected name={"position"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_Table_Phone")} name={"phone_number"}>
                <InputConnected name={"phone_number"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_Table_Email")} name={"email"}>
                <InputConnected name={"email"} ghost={"hover"} />
              </DataRow>

              <FlyoutSectionRow>{t("Prospects_EditingFlyout_Other")}</FlyoutSectionRow>

              <DataRow legend={t("Prospects_EditingFlyout_AdditionalPhone")} name={"additional_phone_number"}>
                <InputConnected name={"additional_phone_number"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_Note")} name={"notes"}>
                <TextareaConnected name={"notes"} ghost={"hover"} />
              </DataRow>
              <DataRow legend={t("Prospects_EditingFlyout_Reusable")} name={"retired"}>
                <div className={"px-3 py-2"}>
                  <CheckboxConnected name={"retired"} />
                </div>
              </DataRow>
              {prospect && (
                <DataRow legend={t("Prospects_EditingFlyout_Created")} name={"created_at"}>
                  <div className={"px-3 py-2"}>
                    {apiDateToJS(prospect.created_at).toLocaleString(getCurrentLanguage(), {dateStyle: "long"})}
                  </div>
                </DataRow>
              )}
            </FlyoutGrid>
          </Flyout>
        </form>
      </FormProvider>
    </WarningsContext>
  )
}

export const DataRow: React.FC<{legend: React.ReactNode; children: React.ReactNode; name: keyof TEditingProspect}> = ({
  legend,
  name,
  children,
}) => {
  const {value: warnings} = WarningsContext.useContext()

  return (
    <FlyoutDataRow
      legend={legend}
      additionalInfo={
        <>
          <FieldErrorMessage name={name} />
          <div className={"text-sm text-cr-yellow"}>{warnings[name]}</div>
        </>
      }
    >
      {children}
    </FlyoutDataRow>
  )
}
