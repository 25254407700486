import React from "react"
import {useNavigate} from "react-router"
import {toast} from "react-toastify"
import {useQueryClient} from "@tanstack/react-query"

import {useLogoutMutation} from "../queries/user.ts"
import {LOGOUT_UNAUTHORIZED_TOAST_ID} from "../services/LogoutIfUnauthorized.tsx"
import {setLSItem, useLSValue} from "./localStorage"
import {tabId} from "./tabId.ts"

export function useRedirectOnUserChange() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const logoutMutation = useLogoutMutation()
  const logout = logoutMutation.mutateAsync

  const currentUser = useLSValue("currentUserId")
  const currentUserIdRef = React.useRef(currentUser?.userId)

  React.useEffect(() => {
    if (currentUser === undefined) {
      setLSItem("currentUserId", {userId: null, tabId})
      return
    }

    if (currentUserIdRef.current === currentUser.userId) {
      return
    }

    currentUserIdRef.current = currentUser.userId

    if (currentUser?.tabId === tabId) {
      return
    }

    if (currentUser.userId === null) {
      logout()

      return
    }

    queryClient.resetQueries().then(() => {
      toast.dismiss(LOGOUT_UNAUTHORIZED_TOAST_ID)
      navigate("/")
    })
  }, [currentUser, logout, navigate, queryClient])
}
