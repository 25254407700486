import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"

import {Loading} from "../../components/Loading"
import {TabItem, TabsContainer} from "../../components/Tabs.tsx"
import {TUploadablePictureOnChange, UploadablePicture} from "../../components/UploadablePicture"
import {i18n} from "../../i18n.ts"
import {useBusinessInfoQuery, useSalespersonQuery, useUpdateSalespersonPictureMutation} from "../../queries/salespeople"
import requestError from "../../services/requestError.tsx"
import {getFullName} from "../../utils"
import {useDocumentTitle, useNumParam, useParam} from "../../utils/hooks"
import {getApiValidationMessages, isAxiosValidationError} from "../../utils/validation"
import {SalespersonBusinessInfo} from "./SalespersonBusinessInfo.tsx"
import {SalespersonProfile} from "./SalespersonProfile.tsx"

type TTab = "profile" | "business_info"

function useTabs(): Array<{tab: TTab; documentTitle: string; title: React.ReactNode}> {
  const {data} = useBusinessInfoQuery()

  return React.useMemo(() => {
    return [
      {
        tab: "profile" as const,
        documentTitle: i18n.t("SalespersonProfile_Profile_DocumentTitle"),
        title: i18n.t("SalespersonProfile_Profile_Title"),
      },
      data && {
        tab: "business_info" as const,
        documentTitle: i18n.t("SalespersonProfile_BusinessInfo_DocumentTitle"),
        title: i18n.t("SalespersonProfile_BusinessInfo_Title"),
      },
    ].filter(tab => !!tab)
  }, [data])
}

export const Salesperson: React.FC = () => {
  const salespersonId = useNumParam("salespersonId")

  const tabs = useTabs()

  const tabParam = useParam("tab", true) as TTab | undefined

  const currentTab = tabs.find(tab => tab.tab === tabParam) ?? tabs[0]

  const navigate = useNavigate()

  useDocumentTitle(currentTab.documentTitle)

  return (
    <div className={"flex justify-center p-10"}>
      <div className={"flex max-w-full grow flex-col gap-8 xl:max-w-screen-xl"}>
        <ProfilePicture salespersonId={salespersonId} />

        <div className={"flex min-w-0 flex-col gap-8"}>
          <TabsContainer size={"sm"}>
            {tabs.map(tab => {
              return (
                <TabItem isActive={tab.tab === currentTab.tab} onClick={() => navigate(`../${tab.tab}`)} key={tab.tab}>
                  {tab.title}
                </TabItem>
              )
            })}
          </TabsContainer>

          {currentTab.tab === "profile" && <SalespersonProfile />}
          {currentTab.tab === "business_info" && <SalespersonBusinessInfo />}
        </div>
      </div>
    </div>
  )
}

export const ProfilePicture: React.FC<{salespersonId: number}> = ({salespersonId}) => {
  const {t} = useTranslation()
  const {data, status} = useSalespersonQuery(salespersonId)
  const updateSalespersonPictureMutation = useUpdateSalespersonPictureMutation()

  const handleChange = useCallback<TUploadablePictureOnChange>(
    async (file: File) => {
      try {
        await updateSalespersonPictureMutation.mutateAsync({salespersonId, file})
      } catch (e) {
        if (!isAxiosValidationError(e)) {
          requestError(e)
          return
        }

        return {message: getApiValidationMessages(e.response.data, "errors").join(", ")}
      }
    },
    [salespersonId, updateSalespersonPictureMutation]
  )

  if (status === "error") {
    return null
  }

  if (!data) {
    return <Loading size={"xl"} containerClassName={"h-[160px] my-6"} />
  }

  return (
    <div className={"my-6 flex w-full gap-8"}>
      <UploadablePicture
        url={data.profile.profile_picture_url ?? undefined}
        pencilPosition={"bottom-right"}
        onChange={handleChange}
        maxSize={10_000_000}
        maxSizeMessage={t("T_The file you have picked is too large. Maximum size 10MB.")}
        rounded
      />

      <div className={"flex grow flex-col gap-2"}>
        <span className={"text-sm text-cr-grey-50"}>{t("T_Salesperson profile")}</span>
        <h1 className={"text-2xl font-semibold"}>{getFullName(data.profile)}</h1>
      </div>
    </div>
  )
}
