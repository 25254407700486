import React, {ChangeEventHandler} from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert"
import {CheckboxBase} from "../../../components/fields/Checkbox"
import {InputBase} from "../../../components/fields/Input"
import {DropdownBase} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types"
import {Loading} from "../../../components/Loading"
import Pagination from "../../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker"
import {Table} from "../../../components/Table"
import {TOrderBy} from "../../../components/Table/shared"
import {i18n} from "../../../i18n"
import type {useDraftTableQuery} from "../../../queries/prospects"
import {useBulkDeprioritizeProspectsMutation, useBulkPrioritizeProspectsMutation} from "../../../queries/prospects"
import requestError from "../../../services/requestError"
import {AAdminProspect, AProspectQueryStatusValues} from "../../../services/types.generated"
import {EMPTY_ARRAY} from "../../../utils"
import {useDebouncedValue} from "../../../utils/hooks"
import {DeletingContext, RejectContext} from "../shared/context"
import {CRMButton} from "../shared/CRMButton.tsx"
import {DataTable, TAdminTableColumn} from "../shared/DataTable.tsx"
import {SendForApprovalButton} from "../shared/SendForApprovalButton.tsx"
import {UploadButton} from "../shared/UploadButton.tsx"
import {AutoApproveButton} from "./components/AutoApproveButton"
import {SkipNotificationApproveButton} from "./components/SkipNotificationApproveButton"

enum EBulkAction {
  PRIORITIZE,
  DEPRIORITIZE,
  DISAPPROVE,
  DELETE,
}

const bulkActionOptions: Array<TOption<EBulkAction>> = [
  {title: i18n.t("Prospects_BulkAction_Prioritize"), value: EBulkAction.PRIORITIZE},
  {title: i18n.t("Prospects_BulkAction_Deprioritize"), value: EBulkAction.DEPRIORITIZE},
  {title: i18n.t("Prospects_BulkAction_Reject"), value: EBulkAction.DISAPPROVE},
  {title: i18n.t("Prospects_BulkAction_Delete"), value: EBulkAction.DELETE},
]

type TProps<TQuery extends typeof useDraftTableQuery> = {
  salesCycleId: number
  assignmentId: number

  approveProspectsButtonType?: "skip notification" | "auto approve"
  isAddButtonVisible?: boolean
  isSendButtonVisible?: boolean | "disabled"
  isRejectedOnlyToggleVisible?: boolean
  isRejectActionVisible?: boolean
  isCRMButtonVisible?: (data: ReturnType<TQuery>["data"]) => boolean
  isWarningsOnly?: boolean

  listQuery: TQuery
  paginationKey: string

  title?: React.ReactNode
}

export function Section<TQuery extends typeof useDraftTableQuery>({
  assignmentId,
  salesCycleId,
  approveProspectsButtonType,
  isSendButtonVisible,
  isAddButtonVisible,
  isRejectedOnlyToggleVisible,
  isRejectActionVisible,
  isCRMButtonVisible,
  isWarningsOnly,
  listQuery,
  title,
  paginationKey,
}: TProps<TQuery>): React.ReactNode {
  const {t} = useTranslation()

  const {setProspects} = RejectContext.useContext()

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TAdminTableColumn>>(undefined)

  const [isRejectedOnly, setIsRejectedOnly] = React.useState(false)

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setSearchString(e.target.value)
  }, [])

  const {setValue: setDeletingProspects} = DeletingContext.useContext()

  const {data, error, isFetching, isLoading, refetch, pagination} = listQuery({
    assignmentId,
    searchString: searchStringDebounced,
    salesCycleId,
    orderBy,
    pageSize: usePageSize(paginationKey),
    status: isRejectedOnly
      ? AProspectQueryStatusValues.Rejected
      : isWarningsOnly
        ? AProspectQueryStatusValues.WithWarnings
        : null,
  })
  const prospects = (data?.data.prospects ?? EMPTY_ARRAY) as AAdminProspect[]

  const {checkedRows, checkRow} = Table.useRowChecking(prospects)

  const bulkPrioritizeMutation = useBulkPrioritizeProspectsMutation()
  const bulkDeprioritizeMutation = useBulkDeprioritizeProspectsMutation()

  const handleBulkAction = React.useCallback(
    async (action: EBulkAction | undefined) => {
      if (action === undefined) {
        return
      }

      if (action === EBulkAction.DELETE) {
        setDeletingProspects(checkedRows as number[])
        return
      }
      if (action === EBulkAction.DISAPPROVE) {
        const selectedProspects = prospects.filter(({id}) => checkedRows.includes(id))
        setProspects(selectedProspects)
        return
      }

      const mutation = {
        [EBulkAction.PRIORITIZE]: bulkPrioritizeMutation,
        [EBulkAction.DEPRIORITIZE]: bulkDeprioritizeMutation,
      }[action]

      try {
        await mutation.mutateAsync(checkedRows as number[])
        refetch()
      } catch (e) {
        requestError(e)
      }
    },
    [
      bulkDeprioritizeMutation,
      bulkPrioritizeMutation,
      checkedRows,
      prospects,
      refetch,
      setDeletingProspects,
      setProspects,
    ]
  )

  const enabledBulkActionOptions = React.useMemo(() => {
    if (isRejectActionVisible) {
      return bulkActionOptions
    }

    return bulkActionOptions.filter(option => option.value !== EBulkAction.DISAPPROVE)
  }, [isRejectActionVisible])

  return (
    <div className={"flex flex-col gap-4"}>
      <h3 className={"font-bold"}>{title}</h3>
      <div className={"flex flex-wrap-reverse justify-between gap-10"}>
        <div className={"flex flex-wrap items-center gap-4"}>
          <div className={"min-w-[150px]"}>
            <DropdownBase
              options={enabledBulkActionOptions}
              placeholder={t("Prospects_BulkAction_Placeholder", {count: checkedRows.length})}
              value={undefined}
              onChange={handleBulkAction}
              disabled={checkedRows.length === 0}
            />
          </div>
          {approveProspectsButtonType === "auto approve" && <AutoApproveButton assignmentId={assignmentId} />}
          {approveProspectsButtonType === "skip notification" && (
            <SkipNotificationApproveButton assignmentId={assignmentId} />
          )}
        </div>
        <div className={"flex flex-wrap items-center gap-4"}>
          {isRejectedOnlyToggleVisible && !isWarningsOnly && (
            <CheckboxBase
              toggle
              onChange={e => {
                setIsRejectedOnly(e.target.checked)
              }}
              checked={isRejectedOnly}
            >
              {t("Prospects_RejectedOnlyToggle")}
            </CheckboxBase>
          )}
          {isAddButtonVisible && <UploadButton variant={"light"} />}
          {isSendButtonVisible && (
            <SendForApprovalButton assignmentId={assignmentId} disabled={isSendButtonVisible === "disabled"} />
          )}
          {isCRMButtonVisible?.(data) && <CRMButton assignmentId={assignmentId} />}
          <InputBase value={searchString} onChange={handleChangeSearchString} placeholder={t("SearchPlaceholder")} />
        </div>
      </div>

      {error ? (
        <GenericErrorAlert />
      ) : isLoading ? (
        <Loading containerClassName={"my-10"} />
      ) : (
        <>
          <DataTable
            data={prospects}
            isLoading={isFetching}
            isPrioritizeActionVisible={true}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            checkedRows={checkedRows}
            checkRow={checkRow}
            refetch={refetch}
          />
          <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
          <Pagination {...pagination} autoHide />
        </>
      )}
    </div>
  )
}
