import React from "react"
import {Controller, FieldPath, FieldValues} from "react-hook-form"

import {pickProps} from "../../../utils/types.ts"
import {useGetFieldVisibleError} from "../../fields/components.tsx"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "../../fields/FieldLabel.tsx"
import {CheckboxesAddContext} from "./context.ts"
import {TCheckboxesAddBaseProps, TCheckboxesAddConnectedProps} from "./types.ts"
import {useContextValue} from "./useContextValue.ts"

export const CheckboxesAddBase: React.FC<TCheckboxesAddBaseProps> = props => {
  const contextValue = CheckboxesAddContext.useProviderValue(useContextValue(props))
  const {Renderer} = contextValue

  return (
    <CheckboxesAddContext value={contextValue}>
      <Renderer />
    </CheckboxesAddContext>
  )
}

export function CheckboxesAddConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  ...props
}: TCheckboxesAddConnectedProps<T, N>) {
  const hasError = !!useGetFieldVisibleError(name)

  return (
    <Controller
      name={name}
      render={({field}) => {
        return <CheckboxesAddBase {...field} {...props} hasError={hasError} />
      }}
    />
  )
}

export function CheckboxesAddField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TCheckboxesAddConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickCheckboxesAddConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <CheckboxesAddConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickCheckboxesAddConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof CheckboxesAddField<T, N>>
) {
  return pickProps<TCheckboxesAddConnectedProps<T, N>>({
    CheckboxList: true,
    AddNew: true,
    Checkbox: true,
    Renderer: true,
    checkValueValid: true,
    readOnly: true,
    disabled: true,
    options: true,
    name: true,
    hasError: true,
    placeholder: true,
    maxValues: true,
  })(props)
}
