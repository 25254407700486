import React from "react"

import {EMPTY_ARRAY} from "../../../utils"
import {useAutoUpdateRef} from "../../../utils/ref.ts"
import {TOption} from "../Dropdown/types.ts"
import {DefaultAddNew} from "./defaultComponents/DefaultAddNew.tsx"
import {DefaultCheckbox} from "./defaultComponents/DefaultCheckbox.tsx"
import {DefaultCheckboxList} from "./defaultComponents/DefaultCheckboxList.tsx"
import {DefaultRenderer} from "./defaultComponents/DefaultRenderer.tsx"
import {TCheckboxesAddBaseProps, TCheckboxesAddContext} from "./types.ts"

export function useContextValue(props: TCheckboxesAddBaseProps): TCheckboxesAddContext {
  const options = useOptions(props.options, props.value)
  const checkValueValid = useCheckValueValid(options, props.checkValueValid)
  const onAdd = useOnAdd(props.value, checkValueValid, props.onChange)
  const onToggleOption = useOnToggleOption(props.value, props.onChange)

  return React.useMemo<TCheckboxesAddContext>(() => {
    return {
      AddNew: props.AddNew ?? DefaultAddNew,
      CheckboxList: props.CheckboxList ?? DefaultCheckboxList,
      Checkbox: props.Checkbox ?? DefaultCheckbox,
      Renderer: props.Renderer ?? DefaultRenderer,
      value: props.value ?? EMPTY_ARRAY,
      maxValues: props.maxValues ?? Infinity,
      inputRef: props.ref,
      hasError: props.hasError,
      readOnly: props.readOnly,
      disabled: props.disabled,
      placeholder: props.placeholder,
      checkValueValid,
      onAdd,
      onChange: props.onChange,
      onToggleOption,
      options,
    }
  }, [
    checkValueValid,
    onAdd,
    onToggleOption,
    options,
    props.AddNew,
    props.Checkbox,
    props.CheckboxList,
    props.Renderer,
    props.disabled,
    props.hasError,
    props.maxValues,
    props.onChange,
    props.placeholder,
    props.readOnly,
    props.ref,
    props.value,
  ])
}

const sanitizeValue = (value: string) => value.trim()

const useOptions = (options: ReadonlyArray<TOption<string>> = EMPTY_ARRAY, value: readonly string[] = EMPTY_ARRAY) => {
  const [customOptions, setCustomOptions] = React.useState<Array<TOption<string>>>([])

  React.useEffect(() => {
    setCustomOptions(customOptions => {
      const valuesNotInOptions = value.filter(
        value =>
          !options.some(option => option.value === value) && !customOptions.some(option => option.value === value)
      )

      if (valuesNotInOptions.length === 0) {
        return customOptions
      }

      return [...customOptions, ...valuesNotInOptions.map(value => ({value, title: value}))]
    })
  }, [options, value])

  return React.useMemo(() => {
    return [...options, ...customOptions]
  }, [options, customOptions])
}

const trueFn = () => true
const emptyFn = () => undefined

const useCheckValueValid = (
  options: ReadonlyArray<TOption<string>> = EMPTY_ARRAY,
  originalFn: (newValue: string) => boolean = trueFn
): TCheckboxesAddContext["checkValueValid"] => {
  return React.useCallback(
    newValue => {
      const sanitizedValue = sanitizeValue(newValue)
      return options.every(option => option.value !== sanitizedValue) && originalFn(sanitizedValue)
    },
    [options, originalFn]
  )
}

const useOnAdd = (
  value: readonly string[] = EMPTY_ARRAY,
  checkValueValid: (newValue: string) => boolean = trueFn,
  onChange: (newValue: string[]) => void = emptyFn
): TCheckboxesAddContext["onAdd"] => {
  const checkValueValidRef = useAutoUpdateRef(checkValueValid)
  const onChangeRef = useAutoUpdateRef(onChange)
  const valueRef = useAutoUpdateRef(value)

  return React.useCallback(
    newValue => {
      if (!checkValueValidRef.current(newValue) || sanitizeValue(newValue) === "") {
        return
      }

      onChangeRef.current([...(valueRef.current ?? []), newValue])
    },
    [checkValueValidRef, onChangeRef, valueRef]
  )
}

const useOnToggleOption = (
  value: TCheckboxesAddContext["value"] = EMPTY_ARRAY,
  onChange: TCheckboxesAddContext["onChange"]
): TCheckboxesAddContext["onToggleOption"] => {
  const valueRef = useAutoUpdateRef(value)
  const onChangeRef = useAutoUpdateRef(onChange)

  return React.useCallback(
    option => () => {
      const index = valueRef.current.indexOf(option.value)

      if (index === -1) {
        onChangeRef.current([...valueRef.current, option.value])
        return
      }

      onChangeRef.current(valueRef.current.toSpliced(index, 1))
    },
    [valueRef, onChangeRef]
  )
}
