import React from "react"
import {useTranslation} from "react-i18next"

import {CheckboxesAddContext} from "../context.ts"
import {TCheckboxesAddRendererProps} from "../types.ts"

export const DefaultRenderer: React.FC<TCheckboxesAddRendererProps> = () => {
  const {t} = useTranslation()

  const {CheckboxList, AddNew} = CheckboxesAddContext.useContext()

  return (
    <div className={"flex flex-col gap-4"}>
      <CheckboxList />

      <div className={"flex flex-col gap-1"}>
        <h2 className={"font-semibold"}>{t("CheckboxesAdd_AddYourOwn")}</h2>
        <AddNew />
      </div>
    </div>
  )
}
