import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import Button from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {EOrderDirection} from "../../../components/Table/shared.ts"
import {sortDate} from "../../../components/Table/sortFunctions.ts"
import {TabsContainer} from "../../../components/Tabs.tsx"
import {
  TSaasCompanyProspectsAssignment,
  TSaasCompanyProspectsIteration,
  useProspectsSalesCycleQuery,
  useSaasCompanyFinishedTableQuery,
} from "../../../queries/prospects.ts"
import {ASaasCompanyProspectsSalesCycle} from "../../../services/types.generated.ts"
import {CombineProviders} from "../../../utils/context.tsx"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam, useParam} from "../../../utils/hooks.tsx"
import {useHorizontalSnapScroll} from "../../../utils/useHorizontalSnapScroll.tsx"
import {PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal} from "../shared/AddProspectsModal"
import {EFeature} from "../shared/AddProspectsModal/utils.ts"
import {AssignmentContext, DeletingContext, EditingContext, IsUploadingContext} from "../shared/context.ts"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {NoProspects} from "../shared/NoProspects.tsx"
import {getActiveIteration, salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {AssignmentPackage} from "./components/AssignmentPackage.tsx"
import {Section} from "./Section.tsx"

export const SaasCompanyProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {data, error, refetch} = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!data) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(data)
  }, [data])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <ProspectsLoaded salesCycle={salesCycle as ASaasCompanyProspectsSalesCycle} />
}

const ProspectsLoaded: React.FC<{salesCycle: ASaasCompanyProspectsSalesCycle}> = ({salesCycle}) => {
  const {t} = useTranslation()
  const isXLSUploadEnabled = useParam("show_admin_buttons", true) === "true"

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const {onScroll, containerClassName, currentItemRef, containerRef, leftButton, rightButton} =
    useHorizontalSnapScroll()

  const assignmentContextValue = AssignmentContext.useProviderValue({
    assignment: null,
    iteration: getActiveIteration(salesCycle),
    salesCycle,
  })
  const {setValue: setAssignmentContext} = assignmentContextValue

  React.useEffect(() => {
    setAssignmentContext(ctx => ({...ctx, salesCycle}))
  }, [salesCycle, setAssignmentContext])

  const activeIteration = (assignmentContextValue.value?.iteration as TSaasCompanyProspectsIteration) ?? null
  const activeAssignment = (assignmentContextValue.value?.assignment as TSaasCompanyProspectsAssignment) ?? null

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(
        sortDate<TSaasCompanyProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC)
      )
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setAssignmentContext(currentValue => ({
        ...currentValue,
        iteration: salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate) ?? null,
      }))
    },
    [salesCycle, setAssignmentContext]
  )

  if (!activeIteration || !activeAssignment) {
    return <Loading />
  }

  return (
    <CombineProviders
      providers={[
        AssignmentContext.combined(assignmentContextValue),
        IsUploadingContext.combined(isUploadingContextValue),
        DeletingContext.combined(deleteContextValue),
        EditingContext.combined(editingContextValue),
      ]}
    >
      <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
        <div>
          <PeriodToggle
            periods={iterationOptions}
            value={activeIteration.start_date}
            onChange={handleChangeIteration}
            noLifetime
          />
        </div>

        <TabsContainer className={"min-h-20"}>
          <Button
            disabled={leftButton.disabled}
            onClick={leftButton.onClick}
            shape={"circular"}
            wrapperClassName={"self-center"}
          >
            <ArrowLeftIcon className={"w-6"} />
          </Button>

          <div
            className={twMerge(["no-scrollbar flex grow scroll-mt-20 gap-10", containerClassName])}
            ref={containerRef}
            onScroll={onScroll}
          >
            {activeIteration?.assignments.map(assignmentPackage => (
              <AssignmentPackage
                key={assignmentPackage.id}
                ref={assignmentPackage.id === activeAssignment.id ? currentItemRef : undefined}
                onClick={() => {
                  setAssignmentContext(value => ({...value, assignment: assignmentPackage}))
                }}
                active={assignmentPackage.id === activeAssignment.id}
                assignmentPackage={assignmentPackage}
              />
            ))}
          </div>

          <Button
            disabled={rightButton.disabled}
            onClick={rightButton.onClick}
            shape={"circular"}
            wrapperClassName={"self-center"}
          >
            <ArrowRightIcon className={"w-6"} />
          </Button>
        </TabsContainer>

        {activeAssignment.prospects_finished_count > 0 ? (
          <Section
            title={
              <>
                {t("Prospects_Finished_Title", {count: activeAssignment.prospects_finished_count})}
                <span className={"ml-2 text-sm font-normal"}>
                  {t("Prospects_Finished_Subtitle", {
                    approved: activeAssignment.prospects_approved_count,
                    rejected: activeAssignment.prospects_finished_count - activeAssignment.prospects_approved_count,
                  })}
                </span>
              </>
            }
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            isCRMButtonVisible={data => data?.data.meta?.sync_crm_button ?? false}
            listQuery={useSaasCompanyFinishedTableQuery}
            paginationKey={"prospects saas company finished"}
          />
        ) : (
          <NoProspects />
        )}
      </LayoutBlock>

      <EditingFlyout />
      <DeleteModal />
      <AddProspectsModal
        enabledFeatures={[isXLSUploadEnabled && EFeature.XLS, EFeature.Single].filter(feature => !!feature)}
      />
    </CombineProviders>
  )
}
