import {
  TAdminsProspectsAssignment,
  TAdminsProspectsIteration,
  TSaasCompanyProspectsAssignment,
  TSaasCompanyProspectsIteration,
  TSalespersonProspectsAssignment,
  TSalespersonProspectsIteration,
} from "../../../queries/prospects.ts"
import {
  AAdminProspect,
  AAdminsProspectsSalesCycle,
  ACompanyUserProspect,
  ASaasCompanyProspectsSalesCycle,
  ASaasCompanyUserProspect,
  ASalesPersonProspectsSalesCycle,
} from "../../../services/types.generated"
import {createSimpleContext, createStateContext} from "../../../utils/context.tsx"
import {getActiveAssignment, getActiveIteration} from "./utils.ts"

export type TRejectProspect = AAdminProspect | ACompanyUserProspect

export type TProspectContext = {
  prospects: TRejectProspect[] | null
  setProspects: (prospect: TRejectProspect[] | null) => void
  rejectionsLeft: number | null
}

export const RejectContext = createSimpleContext<TProspectContext>("reject")

export const WarningsOnlyContext = createStateContext<boolean>("warnings only")

export const AssignmentContext = createStateContext<{
  assignment: TAdminsProspectsAssignment | TSaasCompanyProspectsAssignment | TSalespersonProspectsAssignment | null
  iteration: TAdminsProspectsIteration | TSaasCompanyProspectsIteration | TSalespersonProspectsIteration | null
  salesCycle: AAdminsProspectsSalesCycle | ASaasCompanyProspectsSalesCycle | ASalesPersonProspectsSalesCycle
}>("assignment", desiredValue => {
  const {salesCycle, iteration, assignment} = desiredValue

  if (desiredValue.iteration === null) {
    return {salesCycle, assignment: null, iteration: null}
  }

  const newIteration = getActiveIteration(salesCycle, iteration)
  const newAssignment = getActiveAssignment(newIteration, assignment)

  return {assignment: newAssignment, iteration: newIteration, salesCycle}
})

export type TDeletingProspect = AAdminProspect | ASaasCompanyUserProspect

export const DeletingContext = createStateContext<TDeletingProspect | number[] | null>("deleting")

export type TEditingProspect = AAdminProspect | ASaasCompanyUserProspect

export const EditingContext = createStateContext<TEditingProspect | null>("editing")
export const WarningsContext = createStateContext<{[key in keyof TEditingProspect]?: string}>("warnings")
export const IsUploadingContext = createStateContext<boolean>("isUploading")
