import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../components/Button"
import {FieldErrorMessage} from "../../../components/fields/components"
import {RadioConnected} from "../../../components/fields/RadioButton"
import {TextareaConnected} from "../../../components/fields/Textarea"
import Modal from "../../../components/Modal"
import {AProspectDisapproveReasons} from "../../../services/types.generated"
import {usePrevious} from "../../../utils/hooks"
import {enumToOptions} from "../../../utils/i18n"
import {requiredFieldMessage, setFormErrorsFromAxios, validateNativeEnum} from "../../../utils/validation"
import {RejectContext, TRejectProspect} from "./context"

const presetReasonOptions = enumToOptions("ProspectRejectionReason")

const validationSchema = z
  .object({
    disapprove_reason: validateNativeEnum(AProspectDisapproveReasons),
    disapprove_reason_text: z.string().optional().nullable(),
  })
  .refine(
    ({disapprove_reason, disapprove_reason_text}) =>
      disapprove_reason !== AProspectDisapproveReasons.Other || (disapprove_reason_text ?? "").trim() !== "",
    {path: ["disapprove_reason_text"], message: requiredFieldMessage}
  )

export type TRejectSubmitParams = {
  prospects: TRejectProspect[]
  disapprove_reason: AProspectDisapproveReasons
  disapprove_reason_text: string | undefined
}

export const RejectModal: React.FC<{onSubmit: (params: TRejectSubmitParams) => Promise<any>}> = ({onSubmit}) => {
  const {t} = useTranslation()

  const {prospects: currentProspects, setProspects} = RejectContext.useContext()

  const lastProspects = usePrevious(currentProspects)
  const prospects = currentProspects ?? lastProspects

  const handleClose = React.useCallback(() => {
    setProspects(null)
  }, [setProspects])

  const methods = useForm<{
    disapprove_reason: AProspectDisapproveReasons | null
    disapprove_reason_text: string
  }>({
    defaultValues: {disapprove_reason: null, disapprove_reason_text: ""},
    resolver: zodResolver(validationSchema),
    mode: "onTouched",
  })
  const {getValues, setValue, setError, clearErrors} = methods

  React.useEffect(() => {
    if (!currentProspects) {
      return
    }

    const disapprovedProspect = currentProspects.find(p => p.disapprove_reason)
    clearErrors()
    setValue("disapprove_reason", disapprovedProspect?.disapprove_reason ?? null)
    setValue("disapprove_reason_text", disapprovedProspect?.disapprove_reason_text || "")
  }, [currentProspects, clearErrors, setValue])

  const rejectionReason = methods.watch("disapprove_reason")
  const isOtherTextboxVisible = rejectionReason === AProspectDisapproveReasons.Other

  React.useEffect(() => {
    if (rejectionReason === AProspectDisapproveReasons.Other) {
      return
    }

    clearErrors("disapprove_reason_text")
  }, [clearErrors, rejectionReason])

  const handleSubmit = React.useCallback(async () => {
    try {
      const values = getValues()

      if (!currentProspects || !values.disapprove_reason) {
        return
      }

      await onSubmit({
        prospects: currentProspects,
        disapprove_reason: values.disapprove_reason,
        disapprove_reason_text: values.disapprove_reason_text.trim() || undefined,
      })

      toast.success(
        t("Prospects_Reject_SuccessToast", {
          count: currentProspects.length,
          name: currentProspects[0].organization_name,
        })
      )
      setProspects(null)
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "prospect")
    }
  }, [getValues, currentProspects, onSubmit, t, setProspects, setError])

  return (
    <Modal isOpen={!!currentProspects} onClose={handleClose} size={"lg"}>
      <FormProvider {...methods}>
        <form className={"flex flex-col gap-6"} onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className={"flex flex-col gap-3"}>
            <h2 className={"text-lg font-bold"}>
              {t("Prospects_Reject_Title", {
                count: prospects?.length ?? 0,
                name: prospects?.[0]?.organization_name,
                city: [prospects?.[0]?.city, prospects?.[0]?.country?.name].filter(Boolean).join(", ") || "-",
              })}
            </h2>
            <div className={"text-sm text-cr-grey-50"}>
              {t("Prospects_Reject_Text", {count: prospects?.length ?? 0})}
            </div>
          </div>
          <div className={"flex flex-col gap-4"}>
            <h3 className={"font-bold"}>{t("Prospects_Reject_Reason")}</h3>
            <div className={"flex flex-col gap-2"}>
              {presetReasonOptions.map(({value, title}) => (
                <RadioConnected key={value} value={value} name={"disapprove_reason"}>
                  {title}
                </RadioConnected>
              ))}
            </div>

            <RadioConnected value={AProspectDisapproveReasons.Other} name={"disapprove_reason"}>
              {t("Prospects_Reject_OtherOption")}
            </RadioConnected>
            <FieldErrorMessage name={"disapprove_reason"} />

            <TextareaConnected
              borderClassName={isOtherTextboxVisible ? undefined : "hidden"}
              name={"disapprove_reason_text"}
            />
            <FieldErrorMessage name={"disapprove_reason_text"} />
          </div>

          <div className={"flex justify-end gap-4"}>
            <Button variant={"outlined"} color={"gray"} onClick={handleClose} fullWidth={false}>
              {t("ConfirmModal_CancelButton")}
            </Button>
            <ButtonForm color={"red"} fullWidth={false}>
              {t("Prospects_Reject_SubmitButton")}
            </ButtonForm>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}
