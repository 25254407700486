import React from "react"
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {twMerge} from "tailwind-merge"

import {createSimpleContext} from "../../utils/context.tsx"

const isItemWithKey = (item: React.ReactNode): item is React.ReactNode & {key: React.Key} => {
  return !!item && typeof item === "object" && "key" in item
}

export const Menu: React.FC<{
  className?: string
  placement?: Placement
  Button: React.ComponentType<{isOpen: boolean}>
  items: Array<null | undefined | false | React.ReactNode>
}> = ({Button, placement = "bottom-end", className, items}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const {context, refs, floatingStyles} = useFloating({
    placement,
    middleware: [flip(), offset(10), shift({padding: 16})],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss])

  const contextValue: TMenuContext = {isOpen, onClose: () => setIsOpen(false)}

  const {styles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
    common: ({side}) => ({
      transformOrigin: {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left",
      }[side],
    }),
  })

  return (
    <MenuContext value={contextValue}>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Button isOpen={isOpen} />
      </div>

      {isOpen && (
        <FloatingPortal>
          <div className={"absolute z-[10]"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div
              style={{...styles}}
              className={twMerge([
                "max-h-96 w-56 divide-y divide-cr-grey-5 overflow-y-auto rounded-md bg-cr-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                className,
              ])}
            >
              {items.map((item, i) => {
                if (!item) {
                  return null
                }

                return (
                  <div key={isItemWithKey(item) ? item.key : i} className={"px-1 py-1"}>
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
        </FloatingPortal>
      )}
    </MenuContext>
  )
}

export type TMenuContext = {
  isOpen: boolean
  onClose: () => void
}

export const MenuContext = createSimpleContext<TMenuContext>("menu")
