import React from "react"

import Button from "../../../Button.tsx"
import {CheckboxesAddContext} from "../context.ts"
import {TCheckboxRendererProps} from "../types.ts"

export const DefaultCheckbox: React.FC<TCheckboxRendererProps> = ({option}) => {
  const {disabled, readOnly, value, maxValues, onToggleOption} = CheckboxesAddContext.useContext()

  const isSelected = value.includes(option.value)
  const isMaxValuesReached = value.length >= maxValues

  return (
    <Button
      variant={"outlined"}
      color={isSelected ? "blue" : "gray"}
      disabled={disabled || (!isSelected && isMaxValuesReached)}
      readOnly={readOnly}
      onClick={onToggleOption(option)}
      className={"whitespace-nowrap"}
      data-testid={"checkbox-button"}
    >
      {option.title}
    </Button>
  )
}
