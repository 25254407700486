import React from "react"
import {useTranslation} from "react-i18next"

import {InputBase} from "../../../components/fields/Input"
import {DropdownBase} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {useCountryOptions} from "../../../utils/hooks.tsx"
import {FilteringContext} from "./context.ts"

export const SearchBox: React.FC = () => {
  const {t} = useTranslation()

  const context = React.use(FilteringContext)

  if (!context) {
    return null
  }

  const {searchString} = context

  return (
    <InputBase
      value={searchString.value}
      placeholder={t("SearchPlaceholder")}
      onChange={e => searchString.setValue(e.target.value)}
      borderClassName={"w-full"}
    />
  )
}

export const CountryFilter: React.FC = () => {
  const {t} = useTranslation()

  const {countryId} = FilteringContext.useContext()
  const countryOptions = useCountryOptions()

  return (
    <div className={"w-60"}>
      <DropdownBase
        value={countryId.value}
        options={countryOptions}
        placeholder={t("T_Target market")}
        onChange={countryId.setValue}
      />
    </div>
  )
}
