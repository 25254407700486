import React from "react"
import {Controller, FieldPath, FieldValues} from "react-hook-form"

import {combineRef} from "../../../utils/ref.ts"
import {pickProps} from "../../../utils/types.ts"
import {useGetFieldVisibleError} from "../../fields/components.tsx"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "../../fields/FieldLabel.tsx"
import {DropdownContext} from "./context.ts"
import {TDropdownBaseProps, TDropdownConnectedProps, TDropdownContext} from "./types.ts"
import {useDropdownContextValue} from "./useDropdownContextValue.ts"

function DropdownBaseFn<T>(props: TDropdownBaseProps<T>, ref: React.ForwardedRef<HTMLElement>) {
  const TypedContext = DropdownContext as TDropdownContext<T>

  const contextValue = TypedContext.useProviderValue(useDropdownContextValue(props))
  const {Main} = contextValue.components

  return (
    <TypedContext value={contextValue}>
      <Main ref={combineRef(contextValue.mainButtonRef, ref)} />
    </TypedContext>
  )
}

export const DropdownBase = React.forwardRef(DropdownBaseFn) as <T>(
  props: TDropdownBaseProps<T> & {ref?: React.ForwardedRef<HTMLElement>}
) => ReturnType<typeof DropdownBaseFn<T>>
;(DropdownBase as React.ForwardRefExoticComponent<any>).displayName = "Dropdown"

export function DropdownConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  ...props
}: TDropdownConnectedProps<T, N>) {
  const hasError = !!useGetFieldVisibleError(name)

  return (
    <Controller
      name={name}
      render={({field}) => {
        return <DropdownBase {...field} {...props} hasError={hasError} />
      }}
    />
  )
}

export function DropdownField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TDropdownConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickDropdownConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <DropdownConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickDropdownConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof DropdownField<T, N>>
) {
  return pickProps<TDropdownConnectedProps<T, N>>({
    name: true,
    hasError: true,
    options: true,
    placeholder: true,
    readOnly: true,
    disabled: true,
    addCustom: true,
    clearable: true,
    isCustomValueValid: true,
    isOpen: true,
    Main: true,
    Caret: true,
    keepOpenOnChange: true,
    ClearButton: true,
    List: true,
    setIsOpen: true,
    AddCustom: true,
    Option: true,
    MainButton: true,
    OptionValue: true,
    SelectedValue: true,
    placement: true,
    listWidth: true,
    onBlur: true,
    listClassName: true,
    mainButtonClassName: true,
    caretClassName: true,
    optionValueClassName: true,
    multiple: true,
  })(props)
}
