import React from "react"

import {TOption} from "../../components/formElements/Dropdown/types.ts"
import {TOrderBy} from "../../components/Table/shared.ts"
import {createSimpleContext, createStateContext} from "../../utils/context.tsx"
import {useArrayFilterProperty, useFilterProperties, useStringFilterProperty} from "../../utils/filters.ts"
import {TColumn} from "./table/SaaSUserManagementTable.tsx"
import {EStatus, TUser} from "./fakeTypes.ts"

export const InviteUsersModalContext = createStateContext<boolean>("invite users modal")

export const CancelInvitationContext = createStateContext<TUser | null>("cancel invitation")
export const ReactivateUserContext = createStateContext<TUser | null>("reactivate user")
export const DeactivateUserContext = createStateContext<TUser | null>("deactivate user")

export const FilteringContext = createSimpleContext<ReturnType<typeof useSaasUserManagementTableFiltering>>("filtering")

export const useSaasUserManagementTableFiltering = () => {
  const [orderBy, setOrderBy] = React.useState<TOrderBy<TColumn>>(undefined)

  const filterProperties = useFilterProperties({
    searchString: useStringFilterProperty(),
    status: useArrayFilterProperty<Array<TOption<EStatus>>>(),
  })

  return {
    ...filterProperties,
    orderBy,
    setOrderBy,
  }
}
