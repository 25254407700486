import React from "react"
import {useTranslation} from "react-i18next"

import {
  AActivityFeed,
  AActivityFeedActivityType,
  AProspectActivity,
  AProspectActivityEventEnum,
} from "../../../../../services/types.generated.ts"
import {ActivityBodyCall} from "../bodies/ActivityBodyCall.tsx"
import {ActivityBodyEmail} from "../bodies/ActivityBodyEmail.tsx"
import {ActivityBodyKpiCall} from "../bodies/ActivityBodyKpiCall.tsx"
import {ActivityBodyKpiEmail} from "../bodies/ActivityBodyKpiEmail.tsx"
import {ActivityBodyLost} from "../bodies/ActivityBodyLost.tsx"
import {ActivityBodyMeeting} from "../bodies/ActivityBodyMeeting.tsx"
import {ActivityBodyMeetingScheduled} from "../bodies/ActivityBodyMeetingScheduled.tsx"
import {ActivityBodyPriceOfferSent} from "../bodies/ActivityBodyPriceOfferSent.tsx"
import {ActivityBodyStringNotification} from "../bodies/ActivityBodyStringNotification.tsx"
import {ActivityBodyWon} from "../bodies/ActivityBodyWon.tsx"

export const ActivityBody: React.FC<{activity: AActivityFeed}> = ({activity}) => {
  const Body = React.useMemo(() => {
    switch (activity.activity_type) {
      case AActivityFeedActivityType.Call:
        return ActivityBodyCall
      case AActivityFeedActivityType.Email:
        return ActivityBodyEmail
      case AActivityFeedActivityType.KpiCall:
        return ActivityBodyKpiCall
      case AActivityFeedActivityType.KpiEmail:
        return ActivityBodyKpiEmail
      case AActivityFeedActivityType.Meeting:
        return ActivityBodyMeeting
      case AActivityFeedActivityType.MeetingScheduled:
        return ActivityBodyMeetingScheduled
      case AActivityFeedActivityType.PriceOfferSent:
        return ActivityBodyPriceOfferSent
      case AActivityFeedActivityType.StringNotification:
        return ActivityBodyStringNotification
      case AActivityFeedActivityType.Won:
        return ActivityBodyWon
    }
  }, [activity.activity_type])

  return <Body activity={activity} />
}

export const ActivityBodyProspect: React.FC<{activity: AProspectActivity}> = ({activity}) => {
  const Body = React.useMemo(() => {
    switch (activity.event) {
      case AProspectActivityEventEnum.EmailSent:
        return ActivityBodyEmail
      case AProspectActivityEventEnum.CallingDone:
        return ActivityBodyCall
      case AProspectActivityEventEnum.MeetingHappened:
        return ActivityBodyMeeting
      case AProspectActivityEventEnum.Won:
        return ActivityBodyWon
      case AProspectActivityEventEnum.Lost:
        return ActivityBodyLost
      case AProspectActivityEventEnum.PriceOfferSent:
        return ActivityBodyPriceOfferSent
    }
  }, [activity.event])

  return <Body activity={activity} />
}

export const ActivityNote: React.FC<{activity: Pick<AActivityFeed, "note">}> = ({activity: {note}}) => {
  const {t} = useTranslation()

  if (!note) {
    return null
  }

  return (
    <div className={"flex flex-col gap-2 rounded-lg bg-cr-blue-super-light p-4"}>
      <h3 className={"text-sm font-bold"}>{t("SellingDetails_News_ActivityFeed_Note")}</h3>
      <div className={"whitespace-pre-wrap break-words"}>{note}</div>
    </div>
  )
}
