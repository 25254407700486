import React from "react"

export const getNodeText = (node: React.ReactNode): string => {
  if (node == null) {
    return ""
  }
  if (typeof node === "string" || typeof node === "number" || typeof node === "bigint" || typeof node === "boolean") {
    return String(node)
  }
  if (Array.isArray(node) || (typeof node === "object" && Symbol.iterator in node)) {
    return [...node].map(getNodeText).join("")
  }
  if (
    typeof node === "object" &&
    node &&
    "props" in node &&
    typeof node.props === "object" &&
    node.props &&
    "children" in node.props
  ) {
    return getNodeText(node.props.children as React.ReactNode)
  }
  return ""
}
