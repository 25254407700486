import React from "react"
import {twMerge} from "tailwind-merge"

import {getColumnIndex, TColumnMeta, TColumns, TypedTableContext} from "./shared"
import {ColumnSizeContext} from "./useColumnSizes.ts"

export type TCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
}

export type TCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
  className?: string
}

function DefaultCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
}: TCellValueProps<TCol, TRowData>): React.ReactNode {
  return row[columnMeta.column] ?? "-"
}

export function DefaultCell<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
  className,
}: TCellProps<TCol, TRowData>): React.ReactNode {
  const {columnsMeta, pinnedColumn} = TypedTableContext<TCol, TRowData>().useContext()
  const {getColumnX} = ColumnSizeContext.useContext()

  const Value: React.ComponentType<TCellValueProps<TCol, TRowData>> = columnMeta.CellValue ?? DefaultCellValue
  Value.displayName = Value.displayName ?? "CustomValue"

  const align = columnMeta.align ?? "left"
  const verticalAlign = columnMeta.verticalAlign ?? "center"

  const isSticky =
    (getColumnIndex(columnsMeta, columnMeta.column) ?? Infinity) <= (getColumnIndex(columnsMeta, pinnedColumn) ?? -1)

  return (
    <div
      className={twMerge([
        `bg-cr-white transition-all group-hover/row:bg-cr-blue-super-light`,
        "px-2 py-2.5 text-sm font-normal empty:px-0 md:px-6 md:py-4",
        "border-b border-b-cr-blue-super-light group-last/row:border-b-0 group-first-of-type/row:border-t group-first-of-type/row:border-t-cr-blue-light",
        "flex empty:px-0",
        "[--z:10]",
        isSticky ? `table-sticky-shadow sticky z-[calc(var(--z)+2)]` : "z-[var(--z)]",
        align === "left" && "justify-start",
        align === "center" && "justify-center",
        align === "right" && "justify-end",
        verticalAlign === "top" && "items-start",
        verticalAlign === "center" && "items-center",
        verticalAlign === "bottom" && "items-end",
        className,
      ])}
      style={{left: isSticky ? `${getColumnX(columnMeta.column)}px` : undefined}}
    >
      <Value row={row} columnMeta={columnMeta} />
    </div>
  )
}
