import React from "react"

import {getOptionKey} from "../../Dropdown/types.ts"
import {CheckboxesAddContext} from "../context.ts"
import {TCheckboxListRendererProps} from "../types.ts"

export const DefaultCheckboxList: React.FC<TCheckboxListRendererProps> = () => {
  const {Checkbox, options} = CheckboxesAddContext.useContext()

  return (
    <div className={"flex flex-wrap gap-4"}>
      {options.map(option => (
        <Checkbox key={getOptionKey(option)} option={option} />
      ))}
    </div>
  )
}
