import React from "react"
import {SizeOptions} from "@floating-ui/dom"
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStatus,
  useTransitionStyles,
} from "@floating-ui/react"
import isEqual from "fast-deep-equal"

import {useAutoUpdateRef} from "../../../utils/ref.ts"
import {DefaultAddCustom} from "./defaultComponents/DefaultAddCustom.tsx"
import {DefaultCaret} from "./defaultComponents/DefaultCaret.tsx"
import {DefaultClearButton} from "./defaultComponents/DefaultClearButton.tsx"
import {DefaultList} from "./defaultComponents/DefaultList.tsx"
import {DefaultMain} from "./defaultComponents/DefaultMain.tsx"
import {DefaultMainButton} from "./defaultComponents/DefaultMainButton.tsx"
import {DefaultOption} from "./defaultComponents/DefaultOption.tsx"
import {DefaultOptionValue} from "./defaultComponents/DefaultOptionValue.tsx"
import {DefaultSelectedValue} from "./defaultComponents/DefaultSelectedValue.tsx"
import {TDropdownBaseProps, TDropdownContextValue, TOption} from "./types.ts"

export const useDropdownContextValue = <T>(props: TDropdownBaseProps<T>): TDropdownContextValue<T> => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeOptionIndex, setActiveOptionIndex] = React.useState<number>(0)
  const [customOptions, setCustomOptions] = React.useState<Array<TOption<T>>>([])

  React.useEffect(() => {
    if (props.multiple) {
      setCustomOptions(customOptions => {
        const valuesNotInOptions = (props.value ?? []).filter(
          value =>
            !props.options.some(option => option.value === value) &&
            !customOptions.some(option => option.value === value)
        )

        if (valuesNotInOptions.length === 0) {
          return customOptions
        }

        return [...customOptions, ...valuesNotInOptions.map(value => ({value, title: String(value)}))]
      })

      return
    }

    setCustomOptions(customOptions => {
      const isValueInOptions =
        props.options.some(option => option.value === props.value) ||
        customOptions.some(option => option.value === props.value)

      if (isValueInOptions || props.value == null) {
        return customOptions
      }

      return [...customOptions, {value: props.value, title: String(props.value)}]
    })
  }, [props.options, props.value, props.multiple])

  const options = React.useMemo(() => {
    return [...props.options, ...customOptions]
  }, [props.options, customOptions])

  const propsRef = useAutoUpdateRef({...props, options})

  const isOptionSelected = React.useCallback(
    (option: TOption<T>) => {
      if (props.multiple) {
        return (props.value ?? []).some(value => isEqual(value, option.value))
      }

      return isEqual(props.value, option.value)
    },
    [props.multiple, props.value]
  )

  const firstSelectedOptionIndex = React.useMemo(() => {
    const foundIndex = options.findIndex(isOptionSelected)

    return foundIndex === -1 ? null : foundIndex
  }, [isOptionSelected, options])
  const firstSelectedOptionIndexRef = useAutoUpdateRef(firstSelectedOptionIndex)

  React.useEffect(() => {
    if (props.isOpen === undefined) {
      return
    }

    setIsOpen(props.isOpen)
  }, [props.isOpen])

  React.useEffect(() => {
    if (propsRef.current.setIsOpen === undefined) {
      return
    }

    propsRef.current.setIsOpen(isOpen)
  }, [isOpen, propsRef])

  React.useEffect(() => {
    setActiveOptionIndex(firstSelectedOptionIndexRef.current ?? 0)
  }, [firstSelectedOptionIndexRef, isOpen])

  const handleChange = React.useCallback<TDropdownContextValue<T>["onChange"]>(
    value => {
      const keepOpenOnChange = propsRef.current.keepOpenOnChange ?? !!propsRef.current.multiple
      if (!keepOpenOnChange) {
        setIsOpen(false)
      }

      if (propsRef.current.readOnly || propsRef.current.disabled) {
        return
      }

      if (!propsRef.current.options.some(option => option.value === value)) {
        const newOption = {title: String(value), value: value} satisfies TOption<T>
        setCustomOptions(opts => [...opts, newOption])
      }

      if (!propsRef.current.multiple) {
        propsRef.current.onChange(value)
        return
      }

      const currentValue = propsRef.current.value ?? []
      const valueIndex = currentValue.indexOf(value)
      if (valueIndex === -1 || valueIndex === undefined) {
        propsRef.current.onChange([...currentValue, value])
        return
      }

      propsRef.current.onChange(currentValue.toSpliced(valueIndex, 1))
    },
    [propsRef]
  )

  const handleClear = React.useCallback(() => {
    if (propsRef.current.multiple) {
      propsRef.current.onChange([])
      return
    }
    propsRef.current.onChange(undefined)
  }, [propsRef])

  const getFloatingWidth = React.useCallback(
    ({rects}: Parameters<NonNullable<SizeOptions["apply"]>>[0]) => {
      if (props.listWidth === "reference-auto" || props.listWidth == null) {
        return `${rects.reference.width}px`
      }
      if (props.listWidth === "list-auto") {
        return "auto"
      }

      if (typeof props.listWidth === "number") {
        return `${props.listWidth}px`
      }

      return props.listWidth
    },
    [props.listWidth]
  )

  const {context, refs, floatingStyles} = useFloating({
    placement: props.placement ?? "bottom-start",
    middleware: [
      offset(4),
      flip(),
      shift({padding: 16}),
      size({
        apply(args) {
          Object.assign(args.elements.floating.style, {
            width: getFloatingWidth(args),
            maxHeight: `clamp(0px, ${args.availableHeight}px, 300px)`,
          })
        },
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([dismiss])
  const {styles: floatingContainerStyles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
  })
  const transitionStatus = useTransitionStatus(context)

  const mainButtonRef = React.useRef<HTMLElement | null>(null)

  const isCustomValueValid = React.useCallback(
    (value: string) => {
      if (value === "") {
        return true
      }

      if (propsRef.current.disabled || propsRef.current.readOnly) {
        return false
      }

      return (
        !propsRef.current.options.some(opt => opt.value === value) &&
        (propsRef.current.isCustomValueValid?.(value) ?? true)
      )
    },
    [propsRef]
  )

  const classNames = React.useMemo<TDropdownContextValue<T>["classNames"]>(() => {
    return {
      mainButton: props.mainButtonClassName ?? "",
      list: props.listClassName ?? "",
      caret: props.caretClassName ?? "",
      optionValue: props.optionValueClassName ?? "",
    }
  }, [props.caretClassName, props.listClassName, props.mainButtonClassName, props.optionValueClassName])

  const components = React.useMemo<TDropdownContextValue<T>["components"]>(() => {
    return {
      Main: props.Main ?? DefaultMain,
      MainButton: props.MainButton ?? DefaultMainButton,
      ClearButton: props.ClearButton ?? DefaultClearButton,
      SelectedValue: props.SelectedValue ?? DefaultSelectedValue,
      Caret: props.Caret ?? DefaultCaret,
      List: props.List ?? DefaultList,
      Option: props.Option ?? DefaultOption,
      OptionValue: props.OptionValue ?? DefaultOptionValue,
      AddCustom: props.AddCustom ?? DefaultAddCustom,
    }
  }, [
    props.AddCustom,
    props.Caret,
    props.ClearButton,
    props.List,
    props.Main,
    props.MainButton,
    props.Option,
    props.OptionValue,
    props.SelectedValue,
  ])

  const floating = React.useMemo<TDropdownContextValue<T>["floating"]>(() => {
    return {
      transitionStatus,
      refs,
      context,
      floatingStyles,
      floatingContainerStyles,
      getReferenceProps,
      getFloatingProps,
    }
  }, [context, floatingContainerStyles, floatingStyles, getFloatingProps, getReferenceProps, refs, transitionStatus])

  const multiple = !!props.multiple
  return React.useMemo(() => {
    const common = {
      name: props.name,

      options,

      onChange: handleChange,
      onClear: handleClear,
      onBlur: props.onBlur,

      disabled: props.disabled ?? false,
      readOnly: props.readOnly ?? false,
      hasError: props.hasError ?? false,

      isOpen,
      setIsOpen,

      activeOptionIndex,
      setActiveOptionIndex,

      addCustom: props.addCustom,
      isCustomValueValid,

      isOptionSelected,

      placeholder: props.placeholder,
      clearable: props.clearable,

      mainButtonRef,

      classNames,

      components,
      floating,
    }

    return multiple
      ? {
          ...common,
          multiple: true,
          value: options.filter(opt => (props.value ?? []).includes(opt.value)),
        }
      : {
          ...common,
          multiple: false,
          value: firstSelectedOptionIndex === null ? null : options[firstSelectedOptionIndex],
        }
  }, [
    props.name,
    props.onBlur,
    props.disabled,
    props.readOnly,
    props.hasError,
    props.addCustom,
    props.placeholder,
    props.clearable,
    props.value,
    options,
    handleChange,
    handleClear,
    isOpen,
    activeOptionIndex,
    isCustomValueValid,
    isOptionSelected,
    classNames,
    components,
    floating,
    multiple,
    firstSelectedOptionIndex,
  ])
}
