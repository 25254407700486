import React from "react"
import {useTranslation} from "react-i18next"
import {PlusIcon} from "@heroicons/react/20/solid"

import {TIconComponent} from "../../../../types.ts"
import {InputAction} from "../../../InputAction.tsx"
import {CheckboxesAddContext} from "../context.ts"
import {TAddNewRendererProps} from "../types.ts"

export const DefaultAddNew: React.FC<TAddNewRendererProps> = () => {
  const {placeholder, onAdd, checkValueValid, disabled, readOnly, hasError, inputRef, value, maxValues} =
    CheckboxesAddContext.useContext()
  const isMaxValuesReached = value.length >= maxValues

  return (
    <InputAction
      ref={inputRef}
      onAction={onAdd}
      checkValueValid={checkValueValid}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled || isMaxValuesReached}
      hasError={hasError}
      Icon={AddButton}
    />
  )
}
export const AddButton: TIconComponent = ({className}) => {
  const {t} = useTranslation()

  return <PlusIcon className={className} title={t("T_Add")} />
}
