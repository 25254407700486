import {useTranslation} from "react-i18next"

import {CheckboxesAddField} from "../../components/formElements/CheckboxesAdd/CheckboxesAdd.tsx"
import {TOption} from "../../components/formElements/Dropdown/types.ts"
import {i18n} from "../../i18n"
import {maxProductUniqueItemsCount} from "./stepsConfig.ts"

const optionValues = [
  i18n.t("SalesStrategy_StepUniqueness_NewTechnology"),
  i18n.t("SalesStrategy_StepUniqueness_NewProcess"),
  i18n.t("SalesStrategy_StepUniqueness_BetterExperience"),
  i18n.t("SalesStrategy_StepUniqueness_CreatingNewSegment"),
  i18n.t("SalesStrategy_StepUniqueness_BrandRecognition"),
]
const options: Array<TOption<string>> = optionValues.map(opt => ({title: opt, value: opt}))

const Uniqueness = () => {
  const {t} = useTranslation()

  return (
    <CheckboxesAddField
      name={"product_uniques"}
      label={t("SalesStrategy_StepUniqueness_Title")}
      legend={t("SalesStrategy_StepUniqueness_Description", {count: maxProductUniqueItemsCount})}
      options={options}
      maxValues={maxProductUniqueItemsCount}
    />
  )
}

export default Uniqueness
