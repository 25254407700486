import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import Pagination, {DEFAULT_PAGE_SIZE} from "../../../components/Pagination.tsx"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker.tsx"
import {Table} from "../../../components/Table"
import {useSaasUserManagementQuery} from "../../../queries/saasUserManagement.ts"
import {CombineProviders} from "../../../utils/context.tsx"
import {
  CancelInvitationContext,
  DeactivateUserContext,
  FilteringContext,
  ReactivateUserContext,
  useSaasUserManagementTableFiltering,
} from "../context.ts"
import {ERole, EStatus, TUser} from "../fakeTypes.ts"
import {CancelInvitationModal} from "../modals/CancelInvitationModal.tsx"
import {DeactivateUserModal} from "../modals/DeactivateUserModal.tsx"
import {ReactivateUserModal} from "../modals/ReactivateUserModal.tsx"
import {ActionsCell} from "./ActionsCell.tsx"
import {SearchBox, StatusFilter} from "./Filters.tsx"
import {JoinedAtCell} from "./JoinedAtCell.tsx"
import {RoleCell} from "./RoleCell.tsx"
import {StatusCell} from "./StatusCell.tsx"
import {UserCell} from "./UserCell.tsx"

export type TColumn = "name" | "status" | "role" | "joined_at" | "actions"

export const SaaSUserManagementTable: React.FC<{role?: ERole}> = ({role}) => {
  const {t} = useTranslation()

  const paginationKey = `SaaSUserManagementTable-${role ?? "all"}`

  const filtering = FilteringContext.useProviderValue(useSaasUserManagementTableFiltering())
  const {status, searchString, orderBy, setOrderBy} = filtering

  const {data, error, refetch, pagination, isFetching} = useSaasUserManagementQuery({
    role,
    pageSize: usePageSize(paginationKey, DEFAULT_PAGE_SIZE),
    statuses: status.valueDebounced.map(option => option.value),
    searchString: searchString.valueDebounced,
    sort_by: orderBy?.column,
    sort_direction: orderBy?.direction,
  })

  const cancelInvitationContextValue = CancelInvitationContext.useProviderValue(null)
  const reactivateUserContextValue = ReactivateUserContext.useProviderValue(null)
  const deactivateUserContextValue = DeactivateUserContext.useProviderValue(null)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  return (
    <CombineProviders
      providers={[
        FilteringContext.combined(filtering),
        CancelInvitationContext.combined(cancelInvitationContextValue),
        ReactivateUserContext.combined(reactivateUserContextValue),
        DeactivateUserContext.combined(deactivateUserContextValue),
      ]}
    >
      <div className={"flex flex-col gap-4"}>
        <Table<TColumn, TUser>
          pinnedColumn={"name"}
          orderBy={orderBy}
          onOrder={setOrderBy}
          data={data?.data.users ?? []}
          loading={isFetching}
          columnsMeta={[
            {
              column: "name",
              size: "max-content",
              HeaderCellValue: () => t("SaaSUserManagement_Table_Name"),
              CellValue: UserCell,
              sortFn: true,
              isFiltered: () => searchString.isActive,
              clearFilter: searchString.clear,
              FilterContent: SearchBox,
            },
            {
              column: "status",
              HeaderCellValue: () => t("SaaSUserManagement_Table_Status"),
              CellValue: StatusCell,
              sortFn: true,
              isFiltered: () => status.isActive,
              clearFilter: status.clear,
              FilterContent: StatusFilter,
            },
            {
              column: "role",
              HeaderCellValue: () => t("SaaSUserManagement_Table_Role"),
              CellValue: RoleCell,
              sortFn: true,
            },
            {
              column: "joined_at",
              HeaderCellValue: () => t("SaaSUserManagement_Table_JoinedAt"),
              CellValue: JoinedAtCell,
              sortFn: true,
            },
            {
              column: "actions",
              align: "right",
              size: "max-content",
              HeaderCellValue: () => t("SaaSUserManagement_Table_Actions"),
              CellValue: ActionsCell,
            },
          ]}
        >
          {({data}) => (
            <>
              {data.length ? (
                data.map(row => (
                  <Table.Row
                    key={row.id}
                    row={row}
                    className={twMerge(row.status === EStatus.deactivated && "bg-cr-grey-5")}
                  />
                ))
              ) : (
                <div className={"col-span-full py-7 text-center text-sm"}>{t("SaaSUserManagement_Table_NoUsers")}</div>
              )}
            </>
          )}
        </Table>
      </div>
      <Pagination {...pagination} autoHide />
      <RowCountPickerLS paginationKey={paginationKey} />

      <ReactivateUserModal />
      <DeactivateUserModal />
      <CancelInvitationModal />
    </CombineProviders>
  )
}
