import React from "react"
import {FieldPath, FieldValues} from "react-hook-form"
import {Placement, useFloating, useInteractions, useTransitionStatus, useTransitionStyles} from "@floating-ui/react"

import {TSimpleContext} from "../../../utils/context.tsx"
import {TBaseSharedProps, TConnectedField} from "../../fields/components.tsx"

export type TOption<T> = {
  value: T
  title: React.ReactNode
  key?: string | number
}

export type TDropdownSharedProps<T> = TBaseSharedProps & {
  multiple?: boolean
  name?: string
  onBlur?: React.FocusEventHandler
  disabled?: boolean
  readOnly?: boolean
  hasError?: boolean
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  options: Array<TOption<T>>
  keepOpenOnChange?: boolean
  addCustom?: boolean
  placeholder?: string
  clearable?: boolean
  isCustomValueValid?: (customValue: string) => boolean
  listWidth?: number | "list-auto" | "reference-auto" | string
  placement?: Placement

  mainButtonClassName?: string
  listClassName?: string
  caretClassName?: string
  optionValueClassName?: string
} & Partial<TDropdownCustomComponents>

export type TDropdownBaseProps<T> = TDropdownSharedProps<T> &
  (
    | {
        multiple: true
        value: T[] | undefined | null
        onChange: (value: T[] | undefined) => void
      }
    | {
        multiple?: false | never
        value: T | undefined | null
        onChange: (value: T | undefined) => void
      }
  )

export type TDropdownConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TDropdownSharedProps<unknown> &
  TConnectedField<T, N>

export type TDropdownContextValue<T> = (
  | {
      multiple: true
      value: Array<TOption<T>> | undefined | null
    }
  | {
      multiple: false
      value: TOption<T> | undefined | null
    }
) & {
  name?: string

  options: Array<TOption<T>>

  onChange: (value: T) => void
  onClear: () => void
  onBlur?: React.FocusEventHandler

  disabled: boolean
  readOnly: boolean
  hasError: boolean

  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>

  activeOptionIndex: number
  setActiveOptionIndex: React.Dispatch<React.SetStateAction<number>>

  isOptionSelected: (option: TOption<T>) => boolean

  addCustom?: boolean
  isCustomValueValid?: (customValue: string) => boolean

  placeholder?: string
  clearable?: boolean

  mainButtonRef: React.MutableRefObject<HTMLElement | null>

  classNames: {
    mainButton: string
    list: string
    caret: string
    optionValue: string
  }

  components: TDropdownCustomComponents
  floating: {
    transitionStatus: ReturnType<typeof useTransitionStatus>
    refs: ReturnType<typeof useFloating>["refs"]
    context: ReturnType<typeof useFloating>["context"]
    floatingStyles: ReturnType<typeof useFloating>["floatingStyles"]
    floatingContainerStyles: ReturnType<typeof useTransitionStyles>["styles"]
    getReferenceProps: ReturnType<typeof useInteractions>["getReferenceProps"]
    getFloatingProps: ReturnType<typeof useInteractions>["getFloatingProps"]
  }
}

export type TDropdownContext<T> = TSimpleContext<TDropdownContextValue<T>>

export type TDropdownCustomComponents = {
  Main: React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>
  MainButton: React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>
  ClearButton: React.ComponentType
  SelectedValue: React.ComponentType
  Caret: React.ComponentType
  List: React.ComponentType
  Option: React.ComponentType<TDropdownOptionProps>
  OptionValue: React.ComponentType<TDropdownOptionProps>
  AddCustom: React.ComponentType
}

export type TDropdownOptionProps<T = unknown> = {option: TOption<T>; index: number}

export function getOptionKey(option: TOption<unknown>): React.Key {
  return option.key ?? JSON.stringify(option.value)
}
