import React from "react"
import {useTranslation} from "react-i18next"
import {CalendarDaysIcon} from "@heroicons/react/16/solid"

import {OptionsToggleBase} from "../../../components/fields/OptionsToggle.tsx"
import {DropdownContext} from "../../../components/formElements/Dropdown/context.ts"
import {DropdownBase} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types"
import {getCurrentLanguage} from "../../../i18n"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {usePrevious} from "../../../utils/hooks"

export const lifetimeSymbol: unique symbol = Symbol.for("lifetime")

export type TPeriodToggleValue = typeof lifetimeSymbol | string

export const PeriodToggle: React.FC<{
  value: TPeriodToggleValue
  onChange: (newValue: TPeriodToggleValue) => void
  periods: string[]
  noLifetime?: boolean
}> = ({periods, onChange, value, noLifetime}) => {
  const {t} = useTranslation()

  const options = React.useMemo<Array<TOption<string>>>(() => {
    return periods.map(period => ({
      title: apiDateToJS(period).toLocaleString(getCurrentLanguage(), {
        month: "long",
        year: "2-digit",
      }),
      value: period,
    }))
  }, [periods])

  const previousValue = usePrevious(value)
  const selectedPeriod = React.useMemo(() => {
    if (value !== lifetimeSymbol) {
      return value
    }

    if (previousValue !== lifetimeSymbol && previousValue != null) {
      return previousValue
    }

    return periods[0]
  }, [periods, previousValue, value])

  const toggleOptions = React.useMemo<Array<TOption<TPeriodToggleValue>>>(() => {
    return (
      [
        {
          key: "lifetime",
          value: lifetimeSymbol,
          title: <span className={"line-clamp-1 break-all px-14"}>{t("Reports_PeriodToggle_Lifetime")}</span>,
        },
        {
          key: "period",
          value: selectedPeriod,
          title: (
            <div className={"min-w-40"}>
              <DropdownBase
                options={options}
                value={selectedPeriod}
                onChange={val => val !== undefined && onChange(val)}
                name={"periodPicker"}
                mainButtonClassName={"text-xs input-border-ghost cursor-pointer"}
                caretClassName={"hover:bg-transparent"}
                SelectedValue={SelectedValue}
              />
            </div>
          ),
        },
      ] as const
    ).filter(opt => !noLifetime || opt.value !== lifetimeSymbol)
  }, [noLifetime, onChange, options, selectedPeriod, t])

  return <OptionsToggleBase options={toggleOptions} value={value} onChange={onChange} />
}

export const SelectedValue: React.FC = () => {
  const {value, multiple} = DropdownContext.useContext()

  if (multiple) {
    return null
  }

  return (
    <div className={"flex items-center gap-2 overflow-hidden"}>
      <CalendarDaysIcon className={"-mt-0.5 size-5 shrink-0"} />
      <span className={"grow truncate text-center"}>{value?.title}</span>
    </div>
  )
}
