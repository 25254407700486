import React from "react"
import {FieldPath, FieldValues, RegisterOptions, useFormState} from "react-hook-form"
import {twMerge} from "tailwind-merge"

import {getDeepValue} from "../../utils"
import {getFormValidationMessages, isFormValidationError, TFormValidationError} from "../../utils/validation"

export type TConnectedField<T extends FieldValues, N extends FieldPath<T>> = {
  name: N
  options?: RegisterOptions<T, N>
}

export type TBaseSharedProps = {disabled?: boolean; readOnly?: boolean; hasError?: boolean}

export function useGetFieldVisibleError(name: string | undefined) {
  const {errors, isSubmitted, touchedFields, dirtyFields} = useFormState()

  if (!name) {
    return null
  }

  const isTouched = getDeepValue<boolean | undefined>(touchedFields, name) ?? false
  const isDirty = getDeepValue<boolean | undefined>(dirtyFields, name) ?? false
  const error = name ? getDeepValue<TFormValidationError>(errors, name) : null

  return ((isTouched || isDirty || isSubmitted) && error) || null
}

export const ErrorMessage: React.FC<{className?: string; error: unknown; testId?: string}> = ({
  className,
  error,
  testId,
}) => {
  if (!isFormValidationError(error)) {
    return null
  }

  const messages = getFormValidationMessages(error)
    .map(msg => msg.split("\n"))
    .flat()

  return (
    <div className={"contents"} data-testid={`error-${testId}`}>
      {messages.map((message, index) => (
        <p key={`${index}-${message}`} className={twMerge(["flex items-start gap-2 text-sm text-cr-red", className])}>
          {message}
        </p>
      ))}
    </div>
  )
}

export const FieldErrorMessage: React.FC<{name: string; reserveSpace?: boolean}> = ({name, reserveSpace}) => {
  const error = useGetFieldVisibleError(name)

  if (error) {
    return <ErrorMessage error={error} testId={name} />
  }

  if (reserveSpace) {
    return <div className={"h-5"} />
  }

  return null
}
