import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import Button from "../../../../components/Button"
import {ConfirmModal, VariantModal} from "../../../../components/Modal"
import {useRemoveDisapproveProspectMutation} from "../../../../queries/prospects"
import requestError from "../../../../services/requestError"
import {ACompanyUserProspect, AProspectApprovalStatuses} from "../../../../services/types.generated"
import {commonTransComponents} from "../../../../utils/i18n"
import {RejectContext} from "../../shared/context.ts"

export const RejectButton: React.FC<{
  prospect: ACompanyUserProspect
}> = ({prospect}) => {
  const {t} = useTranslation()

  const {setProspects, rejectionsLeft} = RejectContext.useContext()

  const isRejected = prospect.approval_status === AProspectApprovalStatuses.Disapproved
  const [isUnrejecting, setIsUnrejecting] = React.useState(false)
  const [isRejectingOverLimit, setIsRejectingOverLimit] = React.useState(false)

  const removeDisapproveProspectMutation = useRemoveDisapproveProspectMutation()

  const handleClick = React.useCallback(() => {
    if (isRejected) {
      setIsUnrejecting(true)
      return
    }

    if (rejectionsLeft === 0) {
      setIsRejectingOverLimit(true)
      return
    }

    setProspects([prospect])
  }, [isRejected, prospect, rejectionsLeft, setProspects])

  const handleUnreject = React.useCallback(async () => {
    try {
      await removeDisapproveProspectMutation.mutateAsync(prospect.id)
      toast.success(t("Prospects_Unreject_SuccessToast", {name: prospect.organization_name}))
    } catch (e) {
      requestError(e)
    }
  }, [prospect.id, prospect.organization_name, removeDisapproveProspectMutation, t])

  return (
    <>
      <Button
        variant={isRejected ? "contained" : "outlined"}
        color={isRejected ? "red" : "gray"}
        size={"xs"}
        fullWidth={false}
        onClick={handleClick}
      >
        {isRejected ? t("Prospects_Unreject_Button") : t("Prospects_Reject_Button")}
      </Button>

      <ConfirmModal
        onConfirm={handleUnreject}
        title={t("Prospects_Unreject_Title", {
          name: prospect?.organization_name,
          city: [prospect?.city, prospect?.country?.name].filter(Boolean).join(", ") || "-",
        })}
        isOpen={isUnrejecting}
        onClose={() => setIsUnrejecting(false)}
        confirmButtonText={t("Prospects_Unreject_ConfirmButton")}
      >
        {t("Prospects_Unreject_Text")}
      </ConfirmModal>

      <VariantModal
        variant={"error"}
        title={t("Prospects_Reject_OverLimit_Title")}
        isOpen={isRejectingOverLimit}
        onClose={() => setIsRejectingOverLimit(false)}
        disableCloseButton
        disableClickOutsideClose
        buttons={
          <Button variant={"outlined"} color={"gray"} onClick={() => setIsRejectingOverLimit(false)} fullWidth={false}>
            {t("ConfirmModal_CancelButton")}
          </Button>
        }
      >
        <Trans i18nKey={"Prospects_Reject_OverLimit_Text"} components={commonTransComponents} />
      </VariantModal>
    </>
  )
}
