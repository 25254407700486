import React from "react"
import {debounce} from "lodash"

export type TButtonClickScrollAmountFn = (props: {containerWidth: number; currentItemWidth?: number}) => number
type THorizontalSnapScrollButtonRenderProps = {disabled: boolean; onClick: () => void}
type THorizontalSnapScrollReturn = {
  onScroll: () => void
  containerRef: React.RefObject<HTMLDivElement | null>
  currentItemRef: React.RefCallback<HTMLElement>
  leftButton: THorizontalSnapScrollButtonRenderProps
  rightButton: THorizontalSnapScrollButtonRenderProps
  containerClassName: string
}
export const useHorizontalSnapScroll = ({
  getButtonClickScrollAmount = ({containerWidth}) => containerWidth / 2,
}: {
  getButtonClickScrollAmount?: TButtonClickScrollAmountFn
} = {}): THorizontalSnapScrollReturn => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const [currentItem, setCurrentItem] = React.useState<HTMLElement | null>(null)
  const [isScrolledMin, setIsScrolledMin] = React.useState(true)
  const [isScrolledMax, setIsScrolledMax] = React.useState(true)

  const handleScroll = React.useMemo(
    () =>
      debounce(() => {
        const scroll = containerRef.current?.scrollLeft ?? 0
        const maxScroll = (containerRef.current?.scrollWidth ?? 0) - (containerRef.current?.clientWidth ?? 0)

        setIsScrolledMin(scroll <= 0)
        setIsScrolledMax(scroll >= maxScroll)
      }, 150),
    []
  )

  const handleLeftButtonClick = React.useCallback(() => {
    containerRef.current?.scrollBy({
      left: -getButtonClickScrollAmount({
        containerWidth: containerRef.current?.clientWidth ?? 0,
        currentItemWidth: currentItem?.clientWidth ?? 0,
      }),
      behavior: "smooth",
    })
  }, [currentItem?.clientWidth, getButtonClickScrollAmount])

  const handleRightButtonClick = React.useCallback(() => {
    containerRef.current?.scrollBy({
      left: getButtonClickScrollAmount({
        containerWidth: containerRef.current?.clientWidth ?? 0,
        currentItemWidth: currentItem?.clientWidth ?? 0,
      }),
      behavior: "smooth",
    })
  }, [currentItem?.clientWidth, getButtonClickScrollAmount])

  const scrollToView = React.useCallback(() => {
    if (!containerRef.current || !currentItem) {
      return
    }

    containerRef.current.scrollTo({
      left: currentItem.offsetLeft + currentItem.clientWidth / 2 - containerRef.current.clientWidth / 2,
      behavior: "smooth",
    })
    handleScroll()
  }, [currentItem, handleScroll])

  React.useEffect(() => {
    scrollToView()
  }, [scrollToView])

  const setRef = React.useCallback((item: HTMLElement | null) => {
    setCurrentItem(item)
  }, [])

  return {
    onScroll: handleScroll,
    containerClassName: "overflow-x-auto snap-x scroll-shadow-x snap-mandatory",
    containerRef,
    currentItemRef: setRef,
    leftButton: {disabled: isScrolledMin, onClick: handleLeftButtonClick},
    rightButton: {disabled: isScrolledMax, onClick: handleRightButtonClick},
  }
}
