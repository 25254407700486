import {useTranslation} from "react-i18next"

import {CheckboxesAddField} from "../../components/formElements/CheckboxesAdd/CheckboxesAdd.tsx"
import {TOption} from "../../components/formElements/Dropdown/types.ts"
import {i18n} from "../../i18n"

const optionValues = [
  i18n.t("SalesStrategy_Saving time"),
  i18n.t("SalesStrategy_Saving money"),
  i18n.t("SalesStrategy_Saving on human capacity"),
  i18n.t("SalesStrategy_Earning more money"),
  i18n.t("SalesStrategy_Making process more efficient"),
]
const options: Array<TOption<string>> = optionValues.map(opt => ({title: opt, value: opt}))

const Benefits = () => {
  const {t} = useTranslation()

  return (
    <CheckboxesAddField
      name={"product_benefits"}
      label={t("SalesStrategy_Benefits of your product")}
      legend={t("SalesStrategy_Describe how your solution makes better life for target audience. Choose max 3. items")}
      options={options}
      maxValues={3}
    />
  )
}

export default Benefits
